<template>
  <div id="custom-datepicker">
    <vue-date-picker :clearable="true"
                    :format="format"
                    ref="datepicker"
                    :locale="locale"
                    auto-apply
                    :flow="flow"
                    :enable-time-picker="false"
                    v-model="state.value"
                    :cancel-text="t('datepicker.cancel-text')"
                    :select-text="t('datepicker.select-text')"
                    :placeholder="modelValue.placeholder"
                    @update-month-year="handleMonthYear"/>

    <div v-if="v$.$error" class="custom-input-error">
      {{ t(`errors.${v$.$errors[0].$validator}`) }}
    </div>
  </div>
</template>

<script>
import {computed, reactive, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import moment from "moment/moment";
import {enFormat, getFormat} from "@/assets/js/common";

export default {
  name: "CustomDatePicker",
  components: {
  },
  props: {
    modelValue: {
      type: Object,
      default: () => null
    },
    maxLength: {
      type: Number,
      default: () => 4000,
    }
  },
  setup(props, { emit }) {
    const datepicker = ref(null)
    const { t, locale} = useI18n()

    const format = computed(() => {
      return getFormat(locale.value)
    })
    const state = reactive({
      value: !props.modelValue.isUndefined ? props.modelValue?.value : null
    })

    const formatDate = (inputDate, year=null, month=null) => {
      let currentDate = new Date()
      if(inputDate){
        currentDate = new Date(inputDate)
      }
      if(year){
        currentDate.setFullYear(year)
      }
      if(month){
        currentDate.setMonth(month)
      }
      return  moment(currentDate).format(enFormat.toUpperCase())
    }

    // eslint-disable-next-line no-unused-vars
    const handleMonthYear = ({ instance, month, year }) => {
      state.value = formatDate(state.value, year, month)
    }

    const rules = {
      value: {  }, // Matches state.firstName
    }

    if(props.modelValue.required) {
      rules.value = {
        ...rules.value,
        required
      }
    }

    const v$ = useVuelidate(rules, state)

    watch(() => state.value, (newValue) => {
      const newModelvalue = props.modelValue
      const newDate = moment(newValue)
      newModelvalue.value = null
      if(newDate.isValid()) {
        newModelvalue.value = newDate.format(enFormat.toUpperCase())
      }
      emit('update:modelValue', newModelvalue)
    })

    watch(() => v$.value.$error, (hasError) => {
      const newModelvalue = props.modelValue
      newModelvalue.error = hasError
      emit('update:modelValue', newModelvalue)
    })

    const flow = ref(['year', 'month', 'calendar']);

    return {
      emit,
      state,
      handleMonthYear,
      v$,
      t,
      flow,
      datepicker,
      format,
      locale
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/color.scss";
#custom-datepicker .dp__icon:not(.dp__clear_icon) {
  display: none;
}
#custom-datepicker .dp__input::placeholder {
  color: $color-grey-dark;
  opacity: 0.8;
}

#custom-datepicker .dp__month_year_select {
  font-size: 22px!important;
}

#custom-datepicker .dp__clear_icon {
  right: 20px;
}

#custom-datepicker .dp__input {
  --dp-border-color: #{$color-grey-dark};
  --dp-border-color-hover: #{$color-grey-dark};
  --dp-secondary-color: #{$color-grey-dark};
  width: 100%;
  border-radius: 10px;
  padding: 4px 28px 4px 13px;
  font-size: 16px;
  background: white url("@/assets/icons/calendar.svg") no-repeat right 8px top 50%;
}
</style>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
#custom-datepicker {
  font-weight: 200;
  display: flex;
  flex-direction: column;
  flex: 1;

}

</style>