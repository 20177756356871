<template>
  <FooterComponent :next="next" :btn-text="t('shared.confirm-examination')">
        <CheckBoxComponent class="pointer" :title="t('examination.confirm-title')"
                         v-model="checked" :required="true" />
  </FooterComponent>
</template>

<script>
import {useI18n} from "vue-i18n";
import FooterComponent from "@/components/shared/FooterComponent";
import CheckBoxComponent from "@/components/shared/CheckBoxComponent";
import {ref} from "vue";

export default {
  name: "ExaminationFooterComponent",
  components: {
    CheckBoxComponent,
    FooterComponent
  },
  props: {
    edit: {
      type: Boolean,
      default: () => false
    },
    next: {
      type: String,
      default: () => null
    }
  },
  setup() {
    const checked = ref(false)
    return {
      checked,
      ...useI18n()
    }
  }
}
</script>


<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.footer {
  flex-direction: column;
  --footer-height: 160px;
}
.check-box {
  padding: 0 22px;
  margin-bottom: 12px;
  text-align: left;
  color: $color-black;
}
</style>