<template>
<CaptureComponent :isExamination="true" v-model="saleOrder">
  <template #header>
    <back-navigation-button @click="emit('back', true)"/>
  </template>
</CaptureComponent>
</template>

<script>
import CaptureComponent from "@/components/capture/CaptureComponent";
import {ref, watch} from "vue";
import BackNavigationButton from "@/components/shared/BackNavigationButton";
export default {
  name: "ExaminationComponent",
  components: {
    BackNavigationButton,
    CaptureComponent
  },
  props: {
    modelValue: {
      type: Object,
      default: () => null
    }
  },
  setup(props, { emit }) {
    const saleOrder = ref(props.modelValue)
    watch(saleOrder, (obj) => {
      emit('update:modelValue', obj)
    })
    return {
      saleOrder,
      emit
    }
  }
}
</script>

<style scoped>

</style>