<template>
  <div class="footer">
    <slot></slot>
    <div class="footer__confirm pointer" @click="emit('next', next)">
      {{ btnText }}
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  props: {
    btnText: {
      type: String
    },
    edit: {
      type: Boolean,
      default: () => false
    },
    next: {
      type: String,
      default: () => null
    }
  },
  setup(_, { emit }) {
    return {
      emit
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.footer {
  height: var(--footer-height);
  background-color: $color-grey-very-light;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;

  &__confirm {
    border-radius: 10px;
    color: white;
    text-align: center;
    font-size: 19px;
    max-width: 370px;
    margin: 0 30px;
    padding: 4px 10px 6px;
    width: 100%;
    min-width: 150px;
    background-color: $color-black;
  }
}
</style>