import { createVNode, render } from 'vue'

function renderComponent({ el, component, props, appContext }) {
    let vnode = createVNode(component, props)
    vnode.appContext = { ...appContext }
    render(vnode, el)

    return () => {
        // destroy vnode
        render(null, el)
        vnode = undefined
    }
}

async function sleep(ms) {
    // eslint-disable-next-line no-unused-vars
    return await new Promise((resolve, _) => {
        setTimeout(() => {
            resolve()
        }, ms)
    })
}

async function retrieveElementAsyc(element, selector, function_name="querySelectorAll", isSingle=false, max_retries=3) {
    if(!element) return null
    const component = element[function_name](selector)
    if((isSingle && component) || component?.length || max_retries === 0) return component
    await sleep(200)

    return await retrieveElementAsyc(element, selector, function_name, isSingle, max_retries - 1)
}

export {
    renderComponent,
    retrieveElementAsyc,
    sleep
}