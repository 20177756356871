import {computed, ref} from "vue";

export const utilService = {
  lang: ref(null),
  locale: computed({
    get: () => {
      try {
        const localeCode = localStorage.getItem("locale")
        utilService.lang.value = localeCode ? localeCode : process.env.VUE_APP_VITE_DEFAULT_LOCALE;
      } catch (e) {
        console.error(e);
      }
      return utilService.lang.value;
    },
    set: (value) => {
      if (!value) {
        localStorage.removeItem("locale");
        utilService.lang.value = null;
      } else {

        localStorage.setItem("locale", value);
        utilService.lang.value = value;
      }
    },
  }),
  isEqual(...objects) {
    return objects.every(
      (obj) => JSON.stringify(obj) === JSON.stringify(objects[0])
    );
  },
  copy(jsonObject) {
    return JSON.parse(JSON.stringify(jsonObject));
  },

  instantDownload(response, type="application/pdf", defaultFileName = "Lieferschein.pdf") {
    const contentDisposition = response.request.getResponseHeader(
        "content-disposition"
    );

    let fileName = contentDisposition.replace(/(.*)=/, "") || defaultFileName;
    utilService.downloadFile(fileName, response.data, type)
  },

  remapEntries(item, entryMap){
    const keys = Object.keys(entryMap)
    return Object.fromEntries(
        Object.entries(item).map(([key, value]) => [keys.includes(key) ? entryMap[key] : key, value])
    )
  },

  retrieve_value(item, key, isCopy = false) {
    const keys = key.split(".");

    let content = item;
    if (isCopy) {
      content = utilService.copy(item);
    }
    for (let i = 0; i < keys.length; i++) {
      content = content && keys[i] in content ? content[keys[i]] : null;
    }

    return content;
  },

  downloadFile(fileName, file, type="application/pdf") {
    const url = window.URL.createObjectURL(new Blob([file], {type:type}));
    const link = document.createElement("a");
    link.href = url;

    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  },

  retrieve_values_reverse(item) {
    let newItems = Object.assign({}, item);
    let context = {};
    Object.entries(newItems).forEach(([key, value]) => {
      const keys = key.split(".");
      let prev_key = keys.shift();
      if (!(prev_key in context)) {
        context[prev_key] = null;
      }
      keys.forEach((key, idx, array) => {
        if (!context[prev_key]) {
          context[prev_key] = {};
        }
        if (!(key in context[prev_key])) {
          context[prev_key][key] = idx === array.length - 1 ? value : null;
        }
        prev_key = key;
      });
    });
    return context;
  },

  retrieve_values_query(item) {
    let newItem = Object.assign({}, item);

    newItem = Object.entries(newItem).map(([key, value]) => {
      return [key.split(".").pop(), value];
    });

    return Object.fromEntries(newItem);
  },

  retrieve_assignable_value(item, key, isCopy = false) {
    const keys = key.split(".");
    let content = item;
    if (isCopy) {
      content = utilService.copy(item);
    }

    for (let i = 0; i < keys.length - 1; i++) {
      content = content && keys[i] in content ? content[keys[i]] : null;
    }
    return [content, keys[keys.length - 1]];
  },
  partition(array, isValid) {
    return array.reduce(([pass, fail], elem) => {
      return isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
    }, [[], []]);
  },
  assign_value(item, key, value) {
    let [assignable, last_key] = utilService.retrieve_assignable_value(
      item,
      key
    );
    assignable[last_key] = value;
  },
  assign_value_complex(item_dst, key_dst, item_src, key_src) {
    let [assignable_dst, last_key_dst] = utilService.retrieve_assignable_value(
      item_dst,
      key_dst
    );
    let [assignable_src, last_key_src] = utilService.retrieve_assignable_value(
      item_src,
      key_src
    );
    assignable_dst[last_key_dst] = assignable_src[last_key_src];
  }
};

export function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
const DEFAULT_MAX = 10000000
export function random(max=DEFAULT_MAX, min=0) {
  return Math.floor(Math.random() * (max - min) ) + min;
}
