import { createRouter, createWebHistory } from 'vue-router'
import CaptureView from "@/views/CaptureView";
import ProcessingView from "@/views/ProcessingView";
import {orderService} from "@/services";
import ErrorView from "@/components/error/ErrorView";

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_VITE_BASE_URL),
    routes: [
        {
            path: '/process',
            name: 'process',
            component: ProcessingView
        },
        {
            path: '/',
            name: 'capture',
            component: CaptureView
        },
        {
            path: '/error',
            name: 'error',
            component: ErrorView
        },
    ]
})

const inProcess = ["confirmation", "transmitted", "finish"]

// eslint-disable-next-line no-unused-vars
router.beforeEach(async (to, _, next) => {

    if(to.name === "error") {
        next()
        return
    }

    const query = to.query
    const accessCode = query?.ac

    const storedAccessCode = orderService.data.value?.accessCode
    const storedOrderModel = orderService.data.value?.serviceOrderModel

    orderService.data.value = {
        accessCode: accessCode,
    }

    const serviceOrder = await orderService.getServiceOrder()

    orderService.data.value.serviceOrder = serviceOrder
    if(accessCode === storedAccessCode) {
        orderService.data.value.serviceOrderModel = storedOrderModel
    }
    orderService.pushOrderData()

    let replaceBy = null
    if(serviceOrder){
        if(inProcess.includes(serviceOrder.status)) {
            if(to.name !== "process"){
                replaceBy = "/process"
            }
        }else {
            if(serviceOrder.status === "open") {
                await orderService.postServiceOrderStatus("link_clicked")
            }

            if(to.name !== "capture"){
                replaceBy = "/"
            }
        }

        if(replaceBy){
            next({
                path: replaceBy,
                query: to.query,
                replace: true
            })
        }else {
            next()
        }
    }else {
        next({
            name: "error",
            replace: true
        })
    }
})
export default router