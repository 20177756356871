<template>
  <div class="row">
    <div class="content-gap" v-for="(info, index) in informationObj.items" :key="`container-${info.name}`">
      <ContentComponent :isEdit="isEdit" v-model="informationObj.items[index]" />
    </div>
  </div>
</template>

<script>
import ContentComponent from "@/components/shared/ContentComponent";
import {ref, watch} from "vue";
export default {
  name: "RowContentComponent",
  props: {
    isEdit: {
      type: Boolean,
      default: () => false
    },
    modelValue: {
      type: Object,
      default: () => null
    }
  },
  components: {
    ContentComponent
  },
  setup(props, { emit }) {
    const informationObj = ref(props.modelValue)
    watch(informationObj, (obj) => {
      emit('update:modelValue', obj)
    })
    return {
      informationObj
    }
  }
}
</script>

<style lang="scss" scoped>
.content-gap {
  min-width: 100px;
  margin-right: 8px;
  @media screen and (min-width: 1024px) {
    flex: 1;
  }
}

.content-gap:last-of-type {
  margin-right: 0;
}
</style>