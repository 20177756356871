<template>
  <div class="eseco-header">
    <div>
      {{ t("home.header", { ref: orderRef }) }}
    </div>
    <img alt="logo" :src="require('@/assets/icons/logo.svg')"/>
  </div>
  <RouterView />
</template>

<script>


import {orderService, utilService} from "@/services";
import {computed, onMounted} from "vue";
import {useI18n} from "vue-i18n";
import Tr from "@/i18n/translation";

export default {
  name: 'App',
  setup() {
    const { t } = useI18n()
    const orderRef = computed(() => {
      return orderService.data.value?.serviceOrder?.order_ref ?? "-"
    })
    onMounted(() => {
      Tr.currentLocale = utilService.locale.value
    })
    return {
      t,
      orderRef
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/app.scss";
@import "@/assets/scss/color.scss";
#app {
  font-family: FoundersGrotesk, sans-serif;
  @media screen and (min-width: 768px) {
    width: 62%;
    margin: auto;
    box-shadow: inset 0 0 6px #0000004d;
  }
  @media screen and (min-width: 1024px) {
    width: 48%;
  }
  @media screen and (min-width: 1400px) {
    width: 38%;
  }
}

.vld-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $color-checked-overlay;
}

.vld-container .vl-icon > svg {
  stroke: $color-checked
}

.vl-overlay {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.eseco-header {
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  align-items: center;
  padding-left: 31px;
  padding-right: 22px;
  font-weight: 500;
  height: 80px;
  border-bottom: 1px solid $color-grey-very-light;
}

.custom-input-error {
  font-size: 14px;
  margin-top: 5px;
  color: $color-error;
}

.eseco-header > img {
  width: 110px;
}
</style>
