import {createI18n, useI18n} from "vue-i18n";
import en from "@/i18n/locales/en.json" // <--- add this
import de from "@/i18n/locales/de.json"

export default createI18n({
    locale: process.env.VUE_APP_VITE_DEFAULT_LOCALE, // <--- 1
    fallbackLocale: process.env.VUE_APP_VITE_FALLBACK_LOCALE, // <--- 2
    legacy: false, // <--- 3
    messages: {
        en,
        de
    }
})

export const i18n = {
    setup() {
        // turn `props` into an object of refs, then destructure
        const t = useI18n()
        return {
            t
        }
    }
}