<template>
  <div v-if="localObj" class="row">
    <span>{{t("locale-name")}}</span>
    <CustomSelect v-model="localObj" @change="switchLanguage" :options="supportedLanguages" translate/>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import Tr from "@/i18n/translation"
import CustomSelect from "@/components/shared/CustomSelect";
import {onMounted, ref} from "vue";
import {utilService} from "@/services";
export default {
  name: "LanguageSwitcher",
  components: {
    CustomSelect
  },
  setup() {
    const { t, locale } = useI18n()
    const localObj = ref(null)
    const supportedLanguages = ref([])
    const switchLanguage = async (event) => { // <--- 1
      const lang = event.target.value
      Tr.currentLocale = lang
      utilService.locale.value = lang
    }

    onMounted(() => {
      Tr.supportedLocales.forEach(language => {
        supportedLanguages.value.push({
          "id": language,
          "name": `locale.${language}`
        })
      })

      localObj.value = {
        "id": locale.value
      }
    })



    return {
      supportedLanguages,
      switchLanguage,
      localObj,
      t
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.custom-select {
  flex:1;
  margin-left: 18px;
}
.row {
  align-items: center;
}
.row > span {
  font-size: 19px;
  margin-top: -4px;
  color: $color-black;
  font-weight: 500;
}
</style>