import {MultipleDataTypes} from "@/assets/js/models";
import {register} from "god-tier-serializer";

class ViewModel {
    _props = null
    constructor(props) {
        this._props = props
    }

    get origItems() {
        return this._props
    }

    get items() {
        return this.origItems
    }
}

class ContactViewModel extends ViewModel {
    name = "capture.contact.name"
    _filteredProds = null
    constructor(props) {
        props.items.forEach(async prop => {
            prop.component = "content-component"
        })
        super(props);

        const localityNames = ["zip", "city"]
        // TODO improve filters
        const filteredProps = props.items.filter(prop => !localityNames.includes(prop.dataName))
        const localityProps = props.items.filter(prop => localityNames.includes(prop.dataName))
        const multyDataTypes = new MultipleDataTypes(localityProps)
        multyDataTypes.component = "row-content-component"
        const index = props.items.indexOf(multyDataTypes.first)

        filteredProps.splice(index, 0 , multyDataTypes)

        this._filteredProds = filteredProps
    }

    get items() {
        return this._filteredProds
    }

}


class DeviceViewModel extends ViewModel {
    name = "capture.device.name"
    constructor(props) {
        props.items.forEach(async prop => {
            prop.component = "content-component"
        })
        super(props);
    }

}


register(ViewModel.prototype, "ViewModel")
register(ContactViewModel.prototype, "ContactViewModel")
register(DeviceViewModel.prototype, "DeviceViewModel")

export {
    DeviceViewModel,
    ContactViewModel
}