<template>
  <div class="row action-container">
    <div class="click-box success" @click="emit('onPositiveClick')">
      <div class="container bg-white">
        <SuccessIcon :show-animation="false" />
      </div>
      <p>{{positiveName}}</p>
    </div>
    <div class="click-box" @click="emit('onNegativeClick')">
      <img class="container" :src="require(`@/assets/icons/attention.svg`)"  alt="container"/>
      <p>{{negativeName}}</p>
    </div>
  </div>
</template>

<script>
import SuccessIcon from "@/components/icons/SuccessIcon";
export default {
  name: "ClickBoxComponent",
  components: {
    SuccessIcon
  },
  props: {
    positiveName: {
      type: String
    },
    negativeName: {
      type: String
    }
  },
  setup(_, {emit}) {
    return {
      emit
    }
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.action-container {
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
}
.container {
  position: relative;
  width: 26px;
  height: 26px;
  border-radius: 100%;
}
.click-box {
  max-width: 165px;
  height: 140px;
  text-align: center;
  flex-direction: column;
  display: flex;
  border-radius: 10px;
  background: $color-beige;
  padding: 24px 10px 20px;
  align-items: center;
  flex: 1;
}

.click-box .success-icon {
  display: block;
  height: 26px;
  width: 26px;
}

.click-box > p {
  margin-top: 10px;
  font-size: 20px;
  color: white;
}
.click-box:not(.success) {
  margin-left: 8px;
}

.click-box.success {
  background: $color-checked;
  margin-right: 8px;
}
</style>