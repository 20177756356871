<template>
<div>
  <Wizard
      id="wizard"
      ref="wizard"
      :key="`wizard-${rerenderWizard}`"
      :custom-tabs="customTabs"
  >
    <template v-for="(tab, index) in activeTabs">
      <component v-if="index === currentTabIndex"
                 :is="tab.component" :key="`${tab.component}-${changedView}`"/>
    </template>

    <template #footer>
      <template v-for="(tab, index) in activeTabs">
        <component v-if="index === currentTabIndex" :edit="isEdit" @next="nextTab"
                   :is="tab.footer_component" :key="`${tab.footer_component}-${changedView}`"/>
      </template>
    </template>
  </Wizard>
</div>
</template>

<script>
import Wizard from "form-wizard-vue3";
import {computed, defineAsyncComponent, getCurrentInstance, nextTick, onMounted, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import ProcessInput from "@/components/process/ProcessInput";
import useTab from "@/components/mixins/useTab";
import initTabs from "@/components/mixins/initTabs";
import {orderService} from "@/services";

export default {
  name: "ProcessingView",
  components: {
    Wizard,
    ProcessInput
  },
  setup() {
    const { t, locale } = useI18n()
    const changedView = ref(0)
    const rerenderWizard = ref(0)
    const wizard = ref(null)
    const iconComponent = computed(() => {
      return defineAsyncComponent(() => import("@/components/icons/SuccessIcon"))
    })

    const { appContext } = getCurrentInstance()
    const activeTabs = [
      {
        "footer_component": null,
        "component": "process-input",
        "title": "process.input.name"
      },
      {
        "footer_component": null,
        "component": null,
        "title": "process.handle.name"
      },
      {
        "footer_component": null,
        "component": null,
        "title": "process.finish.name"
      },
    ]
    const { nextTab, currentTabIndex, isEdit } = useTab()
    const mapTitle = () => { return activeTabs.map(tab => { return { title: t(tab.title) } }) }
    const customTabs = ref(mapTitle())
    const getWizardTabIndex = () => {
      const status = orderService.data.value?.serviceOrder?.status

      if(status === "transmitted"){
        return 2
      }else if(status === "finish"){
        return 3
      }
      return 1
    }


    watch(locale, async () => {
      customTabs.value = mapTitle()
      rerenderWizard.value += 1
      await nextTick()
      await initTabs(appContext, activeTabs, wizard, currentTabIndex, iconComponent.value, getWizardTabIndex())
    })

    onMounted(async () => {
      await initTabs(appContext, activeTabs, wizard, currentTabIndex, iconComponent.value, getWizardTabIndex())
    })
    return {
      activeTabs,
      customTabs,
      nextTab,
      currentTabIndex,
      changedView,
      isEdit,
      wizard,
      rerenderWizard
    }
  }
}
</script>

<style lang="scss" scoped>

</style>