<template>
  <div class="error-view">
    <LanguageSwitcher class="error-view__language-switcher"/>

    <div class="error-view__error">
      <div class="error-view__icon">

      </div>
    </div>
    <div class="error-view__title">
      {{ t("errors.view.title") }}
    </div>
    <div class="error-view__description">
      {{ t("errors.view.description") }}
    </div>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import LanguageSwitcher from "@/components/shared/LanguageSwitcher";
export default {
  name: "ErrorView",
  components: {
    LanguageSwitcher
  },
  setup() {
    return {
      ...useI18n()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.error-view {
  margin-top: 60px;
  text-align: center;
  padding: 0 30px 60px;

  &__error {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62px;
    height: 62px;
    border: 1px solid $color-error;
    margin: auto;
  }

  &__language-switcher {
    margin-bottom: 60px;
  }

  &__title {
    margin-top: 52px;
    font-size: 22px;
    color: $color-black;
  }

  &__description {
    margin-top: 38px;
    font-size: 16px;
    line-height: 1.5;
    color: $color-grey-dark;
  }

  &__confirm {
    margin: 45px auto auto;
    font-size: 18px;
    border-radius: 10px;
    color: white;
    max-width: 370px;
    min-width: 200px;
    padding: 2px 6px 6px;
    background-color: $color-black;
  }

  &__icon {
    position: relative;
    background-color: $color-error;
    border-radius: 100%;
    width: 26px;
    height: 26px;
  }

  &__icon:before, &__icon:after {
    position: absolute;
    left: 50%;
    top: 50%;
    content: ' ';
    height: 80%;
    width: 2px;
    background-color: white;
  }
  &__icon:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &__icon:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

}

.submission__icon > .success-icon {
  display: block;
  width: 26px;
  height: 26px;
  --success-icon-color: white;
}
</style>