function scrollToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
}

function copy(value) {
    return JSON.parse(JSON.stringify(value))
}

function scrollToFirstError() {

    const errorDoms = document.querySelectorAll('.v-error');
    if(errorDoms?.length) {
        const errorDomRect = errorDoms[0].getBoundingClientRect()

        window.scrollTo({
            left: errorDomRect.left + document.documentElement.scrollLeft,
            top: errorDomRect.top + document.documentElement.scrollTop - 15,
            behavior: "smooth"
        });
    }

}

const enFormat = "yyyy-MM-dd";

const getFormat = (locale) => {
    return locale === "de" ? "dd.MM.yyyy" : enFormat
}
export {
    scrollToTop,
    copy,
    scrollToFirstError,
    enFormat,
    getFormat
}