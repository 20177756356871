<template>
<div class="process-input">
  <LanguageSwitcher class="capture__language-switcher" />
  <div class="process-input__title">{{ t("process.input.title") }}</div>
  <div class="process-input__description">{{ t("process.input.description") }}</div>

</div>
</template>

<script>
import LanguageSwitcher from "@/components/shared/LanguageSwitcher";
import {useI18n} from "vue-i18n";
export default {
  name: "ProcessInput",
  components: {
    LanguageSwitcher
  },
  setup() {
    const { t } = useI18n()
    return {
      t
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.process-input {
  color: $color-black;

  &__title {
    font-size: 22px;
    margin-top: 35px;
  }

  &__description {
    font-size: 16px;
    line-height: 1.4;
    color: $color-grey-dark;
    margin-top: 12px;
  }

  &__contact {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 35px auto auto;
    max-width: 230px;

    &__title {
      color: $color-grey-dark;
      font-size: 18px;
    }
  }

  &__contact .link {
    color: $color-checked;
    font-size: 18px;
    position: relative;
    padding-left: 18px;
    text-align: start;
    align-self: center;
    text-decoration: underline;
  }

  &__contact .link:before {
    position: absolute;
    content: "";
    width: 16px;
    height: 16px;
    top: 60%;
    left: 0;
    transform: translateY(-50%);
    background: url("@/assets/icons/link.svg") left center/16px 16px no-repeat;
  }
}

.link > a {
  color: inherit;
}
</style>