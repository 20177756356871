<template>
<div class="submission">
  <div class="submission__success">
    <div class="submission__icon">
      <SuccessIcon :showAnimation="false"/>
    </div>
  </div>
  <div class="submission__title">
    {{ t("submission.title") }}
  </div>
  <div class="submission__description">
    {{ t("submission.description") }}
  </div>
  <div class="submission__confirm pointer" @click="onSubmit">
    {{ t("submission.confirm") }}
  </div>
</div>
</template>

<script>
import SuccessIcon from "@/components/icons/SuccessIcon";
import {useI18n} from "vue-i18n";
export default {
  name: "SubmissionComponent",
  components: {
    SuccessIcon
  },
  setup() {
    const onSubmit = () => {
      window.location.reload()
    }
    return {
      onSubmit,
      ...useI18n()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.submission {
  margin-top: 60px;
  text-align: center;
  &__success {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62px;
    height: 62px;
    border: 1px solid $color-checked;
    margin: auto;
  }

  &__title {
    margin-top: 52px;
    font-size: 22px;
    color: $color-black;
  }

  &__description {
    margin-top: 38px;
    font-size: 16px;
    line-height: 1.5;
    color: $color-grey-dark;
  }

  &__confirm {
    margin: 45px auto auto;
    font-size: 18px;
    border-radius: 10px;
    color: white;
    max-width: 370px;
    min-width: 200px;
    padding: 2px 6px 6px;
    background-color: $color-black;
  }

  &__icon {
    position: relative;
    background-color: $color-checked;
    border-radius: 100%;
    width: 26px;
    height: 26px;
  }
}

.submission__icon > .success-icon {
  display: block;
  width: 26px;
  height: 26px;
  --success-icon-color: white;
}
</style>